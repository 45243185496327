import { useState } from "react";

export function useModalGroup<ModalName extends string>(
  defaultEnabledModalName?: ModalName,
): [
  ModalName | undefined,
  {
    openModal: typeof openModal;
    closeModal: typeof closeModal;
  },
] {
  const [openModalName, setOpenModalName] = useState<ModalName | undefined>(
    defaultEnabledModalName,
  );
  const openModal = (modalName: ModalName) => {
    setOpenModalName(modalName);
  };
  const closeModal = () => {
    setOpenModalName(undefined);
  };

  return [
    openModalName,
    {
      openModal,
      closeModal,
    },
  ];
}
