import {
  ILoadMyUserSuccessType,
  LOAD_MY_USER_SUCCESS,
} from "./currentUser/currentUser.actions";

const SET_WAREHOUSE_FILTER = "SET_WAREHOUSE_FILTER";

// type definitions
type WarehouseFilterActionsTypes =
  | ISetWarehouseFilterActionType
  | ILoadMyUserSuccessType;

interface ISetWarehouseFilterActionType {
  type: typeof SET_WAREHOUSE_FILTER;
  payload: boolean;
}

function setWarehouseFilter(status: boolean): ISetWarehouseFilterActionType {
  return { type: SET_WAREHOUSE_FILTER, payload: status };
}

export const warehouseFilterActions = {
  setWarehouseFilter,
};

export function warehouseFilter(
  state = false,
  action: WarehouseFilterActionsTypes,
): boolean {
  switch (action.type) {
    case SET_WAREHOUSE_FILTER:
      return action.payload;
    case LOAD_MY_USER_SUCCESS:
      if (
        "warehouseUser" in action.payload &&
        action.payload.warehouseUser !== undefined &&
        "warehouseUserId" in action.payload.warehouseUser
      ) {
        return true;
      } else {
        return false;
      }
    default:
      return state;
  }
}
