import { ComponentType } from "react";
import { connect } from "react-redux";
import { ConfigProps, reduxForm } from "redux-form";
import {
  IRootStateType,
  IRootStateTypeIndex,
  StoresUnionType,
} from "types/IRootStateType";
import { IStateMappingOptions } from "./FetchConnect";

const mapStoresToProps =
  (stores: IRootStateTypeIndex[], initialValues?: IRootStateTypeIndex) =>
  (state: IRootStateType) => {
    const props: FormProps = { initialValues: {} };
    if (initialValues) {
      props.initialValues = state[initialValues];
    }
    stores.forEach((store: IRootStateTypeIndex) => {
      const storeValue: StoresUnionType = state[store];
      if (storeValue && typeof storeValue === "object") {
        props[store] = state[store];
      }
    });
    return props;
  };

const handleStateMapping = (options: IStateMappingOptions) => {
  if (options.stores) {
    return mapStoresToProps(options.stores, options.initialValues);
  }
  if (options.mapStateToProps) {
    return options.mapStateToProps;
  }
};

export const FetchFormConnect = ({
  state,
  formOptions,
  FormComponent,
}: {
  state: IStateMappingOptions;
  formOptions: ConfigProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormComponent: ComponentType<any>;
}) => {
  return connect(handleStateMapping(state))(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reduxForm<any, any>(formOptions)(FormComponent),
  );
};

type PropsType = {
  [key in IRootStateTypeIndex]?: StoresUnionType;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormProps = { initialValues: StoresUnionType | any } & PropsType;
