import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";

import { authenticationActions } from "slices/authentication/authentication.actions";
import {
  userHasAUserType,
  userHasFeatureFlags,
  userHasPermission,
  userHasPrivileges,
} from "helpers/permissions.helper";
import { dashboardRoutes } from "routes/pageRoutes";
import { FetchControlledFlow } from "views/common/FetchControlledFlow";
import Footer from "views/components/footers/Footer";
import { AdminNavbar } from "views/components/navbars/AdminNavbar";
import NotificationPanel from "views/components/NotificationPanel";
import { Sidebar } from "views/components/sidebars/Sidebar";
import { AddressSetupPage } from "views/pages/AddressSetupPage";
import { LoadingPage } from "views/pages/LoadingPage";
import { ResetPasswordPage } from "views/pages/ResetPasswordPage";
import VerifyContactOptionsPage from "views/pages/VerifyContactOptionsPage";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { SentryRoute } from "services/sentry";
import { currentUserActions } from "slices/currentUser/currentUser.actions";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";
import { FourZeroFourPage } from "views/pages/FourZeroFourPage";
import { localStorageGetItemSafe } from "helpers/localStorage/localStorageGetItemSafe";
import { useModalGroup } from "helpers/hooks/useModalGroup";
import { assertFeatureFlagIsEnabled } from "helpers/assertFeatureFlagIsEnabled.helper";
import { VerifiedIntroModal } from "domains/MyDeliveries/views/VerifiedIntroModal";
import { localStorageSetItemSafe } from "helpers/localStorage/localStorageSetItemSafe";

export const DashboardLayout: React.FC = () => {
  const currentUser = useFetchSelector("currentUser");
  const authentication = useFetchSelector("authentication");
  const applicationSettings = useFetchSelector("applicationSettings");
  const userHasSeenResidentOnboardingPage = localStorageGetItemSafe(
    "hasSeenResidentOnboardingPage",
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [verifyContactOptionsIsSatisfied, setVerifyContactOptionsIsSatisfied] =
    useState(true);

  useEffect(() => {
    if (authentication?.loggedIn !== true) {
      dispatch(authenticationActions.logout("Logout by Admin - !loggedIn"));
    } else {
      // Load user
      dispatch(currentUserActions.loadMyUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unregisterCallback = history.listen((location, action) => {
      if (action === "PUSH") {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement) {
          document.scrollingElement.scrollTop = 0;
        }
      }
    });

    return () => {
      unregisterCallback();
    };
  }, [history]);

  const handleMiniClick = useCallback(() => {
    document.body.classList.toggle("sidebar-mini");
  }, []);

  const hideVerifyContactOptions = useCallback(() => {
    setVerifyContactOptionsIsSatisfied(true);
  }, []);

  const showVerifyContactOptions = useCallback(() => {
    setVerifyContactOptionsIsSatisfied(false);
  }, []);

  const userIsLoaded = currentUser?.userId !== undefined;
  const applicationSettingsHasLoaded = !!applicationSettings?.fetchPackageTypes;
  const hasAUserType = userHasAUserType(currentUser);
  const passwordIsSet = Boolean(
    authentication?.tokens &&
      authentication?.tokens.status !== "NEW_PASSWORD_REQUIRED",
  );

  const isVerified =
    verifyContactOptionsIsSatisfied ||
    Boolean(
      authentication?.tokens?.emailVerified &&
        authentication?.tokens?.phoneVerified,
    );

  const routes = useMemo(() => {
    return dashboardRoutes.map(
      ({
        permissions,
        path,
        component,
        featureFlags,
        privileges,
        permitIfOneOrMoreFeatureFlagsPresent,
      }) => {
        if (
          userHasPermission(currentUser, permissions) &&
          userHasFeatureFlags(
            currentUser,
            featureFlags,
            permitIfOneOrMoreFeatureFlagsPresent,
          ) &&
          userHasPrivileges(currentUser, privileges)
        ) {
          return (
            <SentryRoute
              exact={true}
              path={path}
              component={component}
              key={path}
            />
          );
        }
        /* If user doesn't have permissions, show 404 */
        return (
          <SentryRoute
            exact={true}
            path={path}
            component={FourZeroFourPage}
            key={path}
          />
        );
      },
    );
  }, [currentUser]);
  const [activeModal, modalGroupActions] =
    useModalGroup<"showVerifiedDeliveryIntroModal">();
  const isVerifiedIntroModalEnabled = assertFeatureFlagIsEnabled(
    currentUser,
    "res-independent-verified",
  );
  const hasSeenVerifiedIntroModal = localStorageGetItemSafe(
    "hasSeenVerifiedIntroModal",
  );
  useEffect(() => {
    if (
      !!currentUser?.residentialUser?.residentialUserId &&
      !activeModal &&
      !hasSeenVerifiedIntroModal &&
      isVerifiedIntroModalEnabled
    ) {
      modalGroupActions.openModal("showVerifiedDeliveryIntroModal");
    }
  }, [
    activeModal,
    currentUser?.hasRoleResident,
    currentUser?.residentialUser?.residentialUserId,
    hasSeenVerifiedIntroModal,
    isVerifiedIntroModalEnabled,
    modalGroupActions,
  ]);

  if (!authentication?.loggedIn) {
    return <Redirect to={`/login${location.search}`} />;
  }

  return (
    <FetchControlledFlow
      goal={
        <Suspense fallback={<LoadingPage />}>
          <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
              <AdminNavbar handleMiniClick={handleMiniClick} />
              <NotificationPanel />
              <Switch>
                <SentryRoute
                  exact={true}
                  key="default"
                  path="/"
                  render={() => (
                    <Redirect
                      to={getDefaultPath(
                        currentUser,
                        !!userHasSeenResidentOnboardingPage,
                      )}
                    />
                  )}
                />
                {routes}
                <SentryRoute
                  path="*"
                  render={
                    () => (
                      <FourZeroFourPage />
                    ) /* Fall back to wildcard match of anything else for 404 page */
                  }
                />
              </Switch>
              <Footer fluid={true} />
            </div>
            {activeModal === "showVerifiedDeliveryIntroModal" && (
              <VerifiedIntroModal
                onClickConfirmButton={() => {
                  localStorageSetItemSafe("hasSeenVerifiedIntroModal", true);
                  modalGroupActions.closeModal();
                }}
              />
            )}
          </div>
        </Suspense>
      }
      requirements={[
        {
          component: <LoadingPage />,
          isSatisfied: userIsLoaded && applicationSettingsHasLoaded,
          name: "Loading",
        },
        {
          component: <ResetPasswordPage />,
          isSatisfied: passwordIsSet,
          name: "ResetPassword",
        },
        {
          component: (
            <AddressSetupPage
              showVerifyContactOptions={showVerifyContactOptions}
            />
          ),
          isSatisfied: hasAUserType,
          name: "ApartmentInformation",
        },
        {
          component: (
            <VerifyContactOptionsPage
              hideVerifyContactOptions={hideVerifyContactOptions}
            />
          ),
          isSatisfied: isVerified,
          name: "ContactVerification",
        },
      ]}
    />
  );
};

const getDefaultPath = (
  currentUser?: CurrentUserState,
  userHasSeenResidentOnboardingPage?: boolean,
): string => {
  if (currentUser?.isAdminUser) {
    return "/packages";
  } else if (currentUser?.warehouseUser) {
    return "/packages";
  } else if (currentUser?.driverUser) {
    return "/myDeliveries";
  } else if (currentUser?.residentialUser) {
    if (!userHasSeenResidentOnboardingPage) {
      return "/howDoesFetchWork";
    } else return "/myPackages";
  }
  return "/";
};
