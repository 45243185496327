import {
  CLEAR_PACKAGE,
  GET_PACKAGE_SUCCESS,
} from "slices/package/package.actions";
import { defaultState } from "default-state";
import { IWarehouseLocationStoreType } from "slices/warehouseLocation/warehouseLocation.reducer";

export type PackageStatusEnum = 5 | 10 | 15 | 20 | 25 | number;

export interface IPackageStoreType {
  fetchPackageId: number;
  sender: string;
  carrier: number;
  carrierTracking: string;
  status: PackageStatusEnum;
  shelfLocation: string | null;
  createdDate: string; // iso ex: "2020-04-15T21:19:19.792+0000"
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  userId: number;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  locationCode: string | null;
  systemId: string;
  userCode: string;
  packageType: number | null;
  packageDeliveryPreference: number;
  unitNumber: string;
  unshelved: boolean;
  buildingId: number;
  buildingName: string;
  buildingShortCode: string;
  warehouseId: number;
  warehouseName: string;
  warehouseShortCode: string;
  deliveryWindowId: number | null;
  warehouseLocation: Partial<IWarehouseLocationStoreType> | null;
  warehouseLocationId: number | null;
  warehouseLocationType: number | null;
  warehousePackageType: number | null;
  warehouseTimezone: string;
  driverBatchId: number | null;
  requestedDeliveryStartDate: string | null;
  requestedDeliveryEndDate: string | null;
  deliveredDate: string | null;
  deliveryMethod: number;
  notes: {
    residentDelivery: string | null;
    fetchDelivery: string | null;
    fetchUser: string | null;
    residentUser: string | null;
  };
}
export const initialPackageStore: Partial<IPackageStoreType> = {
  shelfLocation: "",
  createdDate: "",
  createdBy: "",
  lastModifiedDate: "",
  lastModifiedBy: "",
  fetchPackageId: 0,
  sender: "",
  carrier: 0,
  carrierTracking: "",
  status: 2, // Assuming PackageStatusEnum is a number enum
  userId: 0,
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  locationCode: null,
  systemId: "",
  userCode: "",
  // packageType: null,
  packageDeliveryPreference: 0,
  unitNumber: "",
  buildingId: 0,
  buildingName: "",
  buildingShortCode: "",
  warehouseId: 0,
  warehouseName: "",
  warehouseShortCode: "",
  deliveryWindowId: null,
  warehouseLocation: null,
  warehouseLocationId: null,
  warehouseLocationType: null,
  warehousePackageType: null,
  warehouseTimezone: "",
  driverBatchId: null,
  requestedDeliveryStartDate: null,
  requestedDeliveryEndDate: null,
  deliveredDate: null,
  deliveryMethod: 0,
  notes: {
    residentDelivery: null,
    fetchDelivery: null,
    fetchUser: null,
    residentUser: null,
  },
};

export function packageReducer(
  state = defaultState.package,
  action: PackageActionsTypes,
): Partial<IPackageStoreType> {
  switch (action.type) {
    case CLEAR_PACKAGE:
      return defaultState.package;
    case GET_PACKAGE_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}

// type definitions
type PackageActionsTypes =
  | IGetPackageSuccessActionType
  | IClearPackageActionType;

interface IGetPackageSuccessActionType {
  type: typeof GET_PACKAGE_SUCCESS;
  payload: IPackageStoreType;
}
interface IClearPackageActionType {
  type: typeof CLEAR_PACKAGE;
}
