import React from "react";

import moment from "moment-timezone";

import { twentyFourToTwelveHour } from "helpers/date-time.helper";
import { IWarehousePrintersStoreType } from "slices/warehousePrinters/warehousePrinters.reducer";
import {
  IWarehousesStoreType,
  IWarehouseStoreContentType,
} from "slices/warehouses/warehouses.reducer";
import {
  IDeliveryWindowType,
  IWarehouseStoreType,
} from "slices/warehouse/warehouse.reducer";
import { IMarketType } from "slices/markets/markets.reducer";
import { IBuildingByMarketStoreContentType } from "slices/buildingsByMarket/buildingsByMarket.reducer";
import {
  IBuildingStoreContentType,
  IBuildingsStoreType,
} from "slices/buildings/buildings.reducer";
import {
  IBuildingStoreType,
  IUnitType,
} from "slices/building/building.reducer";
import {
  AutoScheduleOptions,
  AutoScheduleOptionsIndex,
  IUserStoreType,
  WindowOptions,
} from "slices/currentUser/currentUser.reducer";
import {
  IUsersStoreContentType,
  IUsersStoreType,
} from "slices/users/users.reducer";
import {
  IDeliveryTimeType,
  IPackageDeliveryTimesStoreType,
} from "slices/packageDeliveryTimes/packageDeliveryTimes.reducer";
import { FetchDesignSelectOptionType } from "views/fetch-design/FetchDesignSelect/FetchDesignSelect";

export const prependAllOption = (
  options: FetchDesignSelectOptionType[],
): FetchDesignSelectOptionType[] => {
  // prepend all to front of options
  options.unshift({
    label: "all",
    value: "",
  });
  return options;
};

export const getWarehouseDeliveryWindowOptions = (
  values: IDeliveryWindowType[],
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (values) {
    for (const entity of values) {
      options.push({
        label: `${entity.startHour}-${entity.endHour}`,
        value: entity.deliveryWindowId.toString(),
      });
    }
  }
  return options;
};

export const getWarehouseOptions = (
  warehouses?: IWarehousesStoreType,
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (warehouses && warehouses.content) {
    for (const entity of warehouses.content) {
      options.push({
        label: entity.shortcode,
        value: entity.warehouseId.toString(),
      });
    }
  }
  return options;
};

export const getMarketOptions = (
  markets: IMarketType[] | null,
): FetchDesignSelectOptionType[] => {
  const options: FetchDesignSelectOptionType[] = [];
  if (markets === null) {
    return options;
  }
  for (const entity of markets) {
    options.push({
      label: entity.marketName,
      value: entity.marketId,
    });
  }
  sortByLabel(options);
  return options;
};

export const getBuildingsByMarketOptions = (
  buildingsByMarket: IBuildingByMarketStoreContentType[] | null,
): FetchDesignSelectOptionType[] => {
  const options: FetchDesignSelectOptionType[] = [];
  if (buildingsByMarket === null) {
    return options;
  }
  for (const entity of buildingsByMarket) {
    options.push({
      label: entity.name,
      value: entity.buildingId,
    });
  }
  return options;
};

export const getBuildingUnitOptions = (
  buildingUnits: IUnitType[],
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (buildingUnits) {
    for (const entity of buildingUnits) {
      options.push({
        label: entity.unitNumber,
        value: entity.buildingUnitId,
      });
    }
  }
  return options;
};

export const getWarehouseDeliveryTimeOptions = (
  packageDeliveryTimes: IPackageDeliveryTimesStoreType,
): FetchDesignSelectOptionType[] => {
  const options = [];
  const timeFormat = "HH";
  const prettyTimeFormat = "ha";
  if (packageDeliveryTimes && packageDeliveryTimes.deliveryTimes) {
    for (const entity of packageDeliveryTimes.deliveryTimes) {
      const startHour = moment(entity.startTime)
        .tz(packageDeliveryTimes.warehouseTimezone)
        .format(timeFormat);
      const endHour = moment(entity.endTime)
        .tz(packageDeliveryTimes.warehouseTimezone)
        .format(timeFormat);
      const prettyStartHour = moment(entity.startTime)
        .tz(packageDeliveryTimes.warehouseTimezone)
        .format(prettyTimeFormat);
      const prettyEndHour = moment(entity.endTime)
        .tz(packageDeliveryTimes.warehouseTimezone)
        .format(prettyTimeFormat);
      options.push({
        label: `${startHour} - ${endHour} (${prettyStartHour} - ${prettyEndHour}) ${getFetchOfferingTypeDescription(
          entity.fetchOfferingType,
        )}`,
        value: entity.deliveryWindowId.toString(),
      });
    }
  }
  return options;
};

const sortByLabel = (optionsList: FetchDesignSelectOptionType[]): void => {
  optionsList.sort(
    (a: FetchDesignSelectOptionType, b: FetchDesignSelectOptionType) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
  );
};

export const formatUserLabel = (userData: IUserDataType): string => {
  return `${userData.firstName + " " + userData.lastName} ${
    userData.userCode
  } ${userData.phone} ${userData.email}`;
};

export const formatDriverUserLabel = (
  userData: IUserStoreType | IUsersStoreContentType,
): string => {
  return `${userData.firstName} ${userData.lastName} ${userData.phone} ${userData.email}`;
};

export const formatBuildingLabel = (
  buildingData: IBuildingStoreContentType | IBuildingStoreType,
): string => {
  const shouldShowWarehouseInfo =
    buildingData.hasOwnProperty("warehouseShortCode") &&
    buildingData.hasOwnProperty("]warehouseName");

  let warehouseString = "";

  if (shouldShowWarehouseInfo) {
    warehouseString = ` - ${
      (buildingData as IBuildingStoreContentType).warehouseShortCode
    } | ${(warehouseString += (buildingData as IBuildingStoreContentType)
      .warehouseName)}`;
  }
  return `${buildingData.shortCode} | ${buildingData.name}${warehouseString}`;
};

export const formatWarehouseLabel = (
  warehouseData: IWarehouseStoreContentType,
): string => {
  return `${warehouseData.name} ${warehouseData.shortcode}`;
};

interface IUserDataType {
  firstName: string;
  lastName: string;
  userId: number;
  userCode: string;
  email: string;
  phone: string;
}

export const getUserOptions = (
  users: IUsersStoreType,
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (users && users.content) {
    for (const entity of users.content) {
      options.push({
        label: formatUserLabel(entity),
        value: entity.userId.toString(),
      });
    }
  }
  return options;
};

export const getDriverUserOptions = (
  users: IUsersStoreType,
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (users && users.content) {
    for (const entity of users.content) {
      options.push({
        label: formatDriverUserLabel(entity),
        value: entity.userId.toString(),
      });
    }
  }
  return options;
};

export const getBuildingOptions = (
  buildings: IBuildingsStoreType,
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (buildings && buildings.content) {
    for (const entity of buildings.content) {
      options.push({
        label: formatBuildingLabel(entity),
        value: entity.buildingId.toString(),
      });
    }
  }
  return options;
};

export const getPrinterOptions = (
  warehousePrinters: IWarehousePrintersStoreType,
): FetchDesignSelectOptionType[] => {
  const options = [];
  if (warehousePrinters && warehousePrinters.content) {
    for (const entity of warehousePrinters.content) {
      options.push({
        label: entity.printerDetails.name,
        value: entity.printerSettingsId.toString(),
      });
    }
  }
  return options;
};

export const appendOptionIfMissing = (
  optionList: FetchDesignSelectOptionType[],
  newOption: FetchDesignSelectOptionType,
): void => {
  // If option with matching value doesn't exist, append to list
  if (
    optionList.filter(
      (option: FetchDesignSelectOptionType) => option.value === newOption.value,
    ).length === 0
  ) {
    optionList.push(newOption);
  }
};

export const getSelectedOption: (
  value: string | number | undefined,
  options?: FetchDesignSelectOptionType[],
) => FetchDesignSelectOptionType | null = (
  value: string | number | undefined,
  options?: FetchDesignSelectOptionType[],
): FetchDesignSelectOptionType | null => {
  const selectedOptionsExist: boolean =
    !!options &&
    options.filter((option) => option.value === `${value}`).length > 0;
  const result =
    options && selectedOptionsExist
      ? options.filter((option) => option.value === `${value}`)[0]
      : null;
  return result ? result : null;
};

export const getSelectedFetchOption: (
  value: string | number,
  options: FetchDesignSelectOptionType[],
) => FetchDesignSelectOptionType | null = (
  value: string | number,
  options: FetchDesignSelectOptionType[],
): FetchDesignSelectOptionType | null => {
  return options.reduce(
    (selectionOption: FetchDesignSelectOptionType | null, option) => {
      if (option.value === `${value}`) {
        return option;
      }
      return selectionOption;
    },
    null,
  );
};

export const getBooleanOptions = (): FetchDesignSelectOptionType[] => {
  return [
    {
      label: "True",
      value: "true",
    },
    {
      label: "False",
      value: "false",
    },
  ];
};

export const getDriverStatusOptions = (): FetchDesignSelectOptionType[] => {
  return [
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Disabled",
      value: "DISABLED",
    },
  ];
};

export const getWarehouseLocationBatchCategoryOptions =
  (): FetchDesignSelectOptionType[] => {
    return [
      {
        label: "Building-Specific",
        value: "building",
      },
      {
        label: "Facility-Universal",
        value: "warehouse",
      },
      {
        label: "Building-Transition",
        value: "transition",
      },
    ];
  };

export const getAutoScheduleOptions = (
  warehouse: IWarehouseStoreType,
  type: "weekday" | "saturday" | "sunday",
): FetchDesignSelectOptionType[] => {
  const options = [];
  options.push({
    label: "Next Available",
    value: "-1",
  });
  if (warehouse.deliveryWindows) {
    const sortedDeliveryWindows = warehouse.deliveryWindows.sort((a, b) => {
      if (a.startHour < b.startHour) {
        return -1;
      } else if (a.startHour > b.startHour) {
        return 1;
      }
      return 0;
    });
    for (const entity of sortedDeliveryWindows) {
      if (
        (type === "weekday" && entity.availableOnMonday) ||
        (type === "saturday" && entity.availableOnSaturday) ||
        (type === "sunday" && entity.availableOnSunday)
      ) {
        options.push({
          label: `${entity.startHour}-${
            entity.endHour
          } (${twentyFourToTwelveHour(
            entity.startHour,
          )}-${twentyFourToTwelveHour(entity.endHour)})`,
          value: entity.deliveryWindowId.toString(),
        });
      }
    }
  }
  return options;
};

export const getSelectListOptionsFromAutoScheduleOptions = (
  options: AutoScheduleOptions,
): AutoScheduleSelectListOptionDictionary => {
  const defaultOptions = [
    {
      label: "Disabled",
      value: "0",
    },
    {
      label: "Next Available",
      value: "-1",
    },
  ];

  return {
    weekdayOptions: [
      ...defaultOptions,
      ...options.weekdayOptions
        .sort(sortDeliveryWindowsAsc)
        .map(mapAutoScheduleOptionToSelectListOption),
    ],
    saturdayOptions: [
      ...defaultOptions,
      ...options.saturdayOptions
        .sort(sortDeliveryWindowsAsc)
        .map(mapAutoScheduleOptionToSelectListOption),
    ],
    sundayOptions: [
      ...defaultOptions,
      ...options.sundayOptions
        .sort(sortDeliveryWindowsAsc)
        .map(mapAutoScheduleOptionToSelectListOption),
    ],
  };
};
const mapAutoScheduleOptionToSelectListOption = ({
  startTime,
  endTime,
  deliveryWindowId,
}: WindowOptions) => ({
  label: `${moment(startTime, "HH:mm").format("HH")}-${moment(
    endTime,
    "HH:mm",
  ).format("HH")} (${moment(startTime, "HH:mm").format("ha")}-${moment(
    endTime,
    "HH:mm",
  ).format("ha")})`,
  value: deliveryWindowId.toString(),
});

const sortDeliveryWindowsAsc = (a: WindowOptions, b: WindowOptions) => {
  const aStartTime = moment(a.startTime, "HH:mm");
  const bStartTime = moment(b.startTime, "HH:mm");
  if (aStartTime.isBefore(bStartTime)) {
    return -1;
  } else if (aStartTime.isAfter(bStartTime)) {
    return 1;
  }
  return 0;
};

export type AutoScheduleSelectListOptionDictionary = {
  [key in AutoScheduleOptionsIndex]: FetchDesignSelectOptionType[];
};

function getFetchOfferingTypeDescription(
  fetchOfferingType: IDeliveryTimeType["fetchOfferingType"],
) {
  switch (fetchOfferingType) {
    case 1:
      return "Standard";
    case 2:
      return "Oversize";
    default:
      return "";
  }
}
