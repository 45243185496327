import {
  takeLatest,
  takeLeading,
  call,
  put,
  select,
  take,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import { change, initialize } from "redux-form";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  packageActions,
  SEARCH_WINDOW_PACKAGES_SUCCESS,
} from "slices/package/package.actions";
import { DELIVERY_SCHEDULED } from "constants/generalSettings/packageStatuses";
import { getTodaysDateString } from "helpers/date-time.helper";
import { IRootStateType } from "types/IRootStateType";
import {
  ISaveBatchActionType,
  IGetBatchActionType,
  batchActions,
  IGetBatchPackagesActionType,
  GET_PACKAGES_FROM_BATCH_SUCCESS,
  GET_BATCH,
  SAVE_BATCH,
  SAVE_BATCH_SUCCESS,
  GET_BATCH_PACKAGES,
  GET_PACKAGES_FROM_BATCH,
} from "./batch.actions";

import { IBatchStoreType } from "./batch.reducer";
import { IBatchesStoreType } from "slices/batches/batches.reducer";
import { driverBatchesServices } from "services/hermes/driverBatches";
import { removeWarning } from "slices/navigateAway/navigateAwayState";
import { objectNumbersToStrings } from "helpers/transformations/objectNumbersToStrings";
import { BATCH_FORMNAME } from "constants/form-names";

function* saveBatchSuccessWorker(action: ISaveBatchActionType) {
  action.onSuccessConfig?.callback &&
    action.onSuccessConfig?.callback(action.payload);
  // display success message
  toast.success("Batch updated successfully");
}

function* getBatchWorker(action: IGetBatchActionType) {
  yield put(batchActions.clearBatch());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof driverBatchesServices.getBatch> =
      yield call(driverBatchesServices.getBatch, action.payload.batchId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBatchStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(batchActions.getBatchSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

function* getPackagesFromBatchWorker(action: IGetBatchActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof driverBatchesServices.getBatch> =
    yield call(driverBatchesServices.getBatch, action.payload.batchId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IBatchStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(batchActions.getPackagesFromBatchSuccess(response));
}

function* saveBatchWorker(action: ISaveBatchActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof driverBatchesServices.saveBatch> =
    yield call(driverBatchesServices.saveBatch, action.payload);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IBatchesStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(batchActions.saveBatchSuccess(response, action.onSuccessConfig));
}

function* getBatchPackagesWorker(action: IGetBatchPackagesActionType) {
  const { batchId, buildingId, deliveryWindowId, physicalWarehouseId } =
    action.payload;
  if (batchId) {
    yield put(batchActions.getPackagesFromBatch(batchId));
    // Wait for GET_BATCH_SUCCESS before searching for packages
    yield take(GET_PACKAGES_FROM_BATCH_SUCCESS);
  }
  const filters = [
    { id: "buildingId", value: buildingId },
    {
      id: "deliveryWindowId",
      value: deliveryWindowId,
    },
    { id: "requestedDeliveryStartDate", value: getTodaysDateString() },
    { id: "status", value: DELIVERY_SCHEDULED },
  ];
  if (physicalWarehouseId) {
    filters.push({ id: "physicalWarehouseId", value: physicalWarehouseId });
  }
  yield put(
    packageActions.searchWindowPackages({
      filters,
      page: 0,
      pageSize: 10000,
      sort: { id: "fetchPackageId", asc: true },
    }),
  );
  // wait until update
  yield take(SEARCH_WINDOW_PACKAGES_SUCCESS);
  // get batch from state
  const getStateBatch = (state: IRootStateType) => state.batch;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const batch: IBatchStoreType = yield select(getStateBatch);
  if (!!batch && "packages" in batch && !!batch.packages) {
    // update redux batch form packages field
    yield put(change("batch", "packages", batch.packages));
  }
}

export const batchSagas = function* sagas() {
  yield takeLeading(GET_BATCH, getBatchWorker);
  yield takeLeading(SAVE_BATCH, saveBatchWorker);
  yield takeLeading(SAVE_BATCH_SUCCESS, saveBatchSuccessWorker);
  yield takeLatest(GET_BATCH_PACKAGES, getBatchPackagesWorker);
  yield takeLatest(GET_PACKAGES_FROM_BATCH, getPackagesFromBatchWorker);
};
