import {
  IIncludePackagesInBatchActionType,
  IIncludePackageInBatchActionType,
  IGetPackagesFromBatchSuccessActionType,
  IGetBatchSuccessActionType,
  IExcludePackagesFromBatchActionType,
  IExcludePackageFromBatchActionType,
  IClearBatchPackagesActionType,
  IClearBatchActionType,
  CLEAR_BATCH,
  CLEAR_BATCH_PACKAGES,
  EXCLUDE_PACKAGES_FROM_BATCH,
  EXCLUDE_PACKAGE_FROM_BATCH,
  GET_BATCH_SUCCESS,
  GET_PACKAGES_FROM_BATCH_SUCCESS,
  INCLUDE_PACKAGES_IN_BATCH,
  INCLUDE_PACKAGE_IN_BATCH,
} from "slices/batch/batch.actions";
import {
  ISearchWindowPackagesSuccessActionType,
  SEARCH_WINDOW_PACKAGES_SUCCESS,
} from "slices/package/package.actions";
import { defaultState } from "default-state";
import { IWarehouseLocationStoreType } from "slices/warehouseLocation/warehouseLocation.reducer";
import { IPackageStoreType } from "slices/package/package.reducer";

export interface IBatchPackageStoreType extends IPackageStoreType {
  excludedFromBatch?: boolean;
  warehouseLocation: Partial<IWarehouseLocationStoreType> | null;
}

interface IBatchAuditOwnerStoreType {
  firstName: string;
  lastName: string;
  systemId: number;
  userId: number;
}

export interface IBatchStoreType {
  driverBatchId?: number;
  buildingId: number;
  driverUserId: number;
  deliveryStartDate: string;
  deliveryEndDate: string;
  active: boolean;
  driverFirstName: string;
  driverLastName: string;
  driverEmail: string;
  driverPhone: string;
  buildingName: string;
  buildingShortCode: string;
  auditStatus: string;
  auditOwner?: IBatchAuditOwnerStoreType;
  deliveryWindowId: number;
  packages: IBatchPackageStoreType[];
}

type BatchActionsTypes =
  | IGetBatchSuccessActionType
  | IGetPackagesFromBatchSuccessActionType
  | ISearchWindowPackagesSuccessActionType
  | IIncludePackageInBatchActionType
  | IExcludePackageFromBatchActionType
  | IClearBatchActionType
  | IClearBatchPackagesActionType
  | IIncludePackagesInBatchActionType
  | IExcludePackagesFromBatchActionType;

export function batch(
  state: Partial<IBatchStoreType> = defaultState.batch,
  action: BatchActionsTypes,
): Partial<IBatchStoreType> {
  switch (action.type) {
    case CLEAR_BATCH:
      return defaultState.batch;
    case GET_BATCH_SUCCESS:
      return action.payload;
    case GET_PACKAGES_FROM_BATCH_SUCCESS:
      return {
        ...state,
        packages: action.payload.packages,
      };
    case CLEAR_BATCH_PACKAGES:
      return {
        ...state,
        packages: [],
      };
    case EXCLUDE_PACKAGE_FROM_BATCH:
      return {
        ...state,
        packages: updateBatchPackageInclusion(
          !!state.packages ? state.packages : [],
          action.payload,
          true,
        ),
      };
    case EXCLUDE_PACKAGES_FROM_BATCH:
      return {
        ...state,
        packages: !!state.packages
          ? state.packages.map((fetchPackage) => ({
              ...fetchPackage,
              excludedFromBatch: action.payload.includes(
                fetchPackage.fetchPackageId,
              )
                ? true
                : fetchPackage.excludedFromBatch,
            }))
          : [],
      };
    case INCLUDE_PACKAGE_IN_BATCH:
      return {
        ...state,
        packages: updateBatchPackageInclusion(
          !!state.packages ? state.packages : [],
          action.payload,
          false,
        ),
      };
    case INCLUDE_PACKAGES_IN_BATCH:
      return {
        ...state,
        packages: !!state.packages
          ? state.packages.map((fetchPackage) => ({
              ...fetchPackage,
              excludedFromBatch: action.payload.includes(
                fetchPackage.fetchPackageId,
              )
                ? false
                : fetchPackage.excludedFromBatch,
            }))
          : [],
      };

    case SEARCH_WINDOW_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: combinePackageList(
          !!state.packages ? state.packages : [],
          action.payload.content,
          !!state.driverBatchId ? state.driverBatchId : null,
        ),
      };
    default:
      return state;
  }
}

function updateBatchPackageInclusion(
  packageList: IBatchPackageStoreType[],
  packageId: number,
  newExclusionStatus: boolean,
) {
  // duplicate original
  const updatedPackages = JSON.parse(
    JSON.stringify(packageList),
  ) as IBatchPackageStoreType[];
  // keep existing status unless fetchPackageId matches
  updatedPackages.forEach((fetchPackage: IBatchPackageStoreType) => {
    fetchPackage.excludedFromBatch =
      packageId === fetchPackage.fetchPackageId
        ? newExclusionStatus
        : fetchPackage.excludedFromBatch;
  });
  return updatedPackages;
}

// set package status
function setPackagesIncludedInBatchStatus(
  packageList: IBatchPackageStoreType[],
  excludedFromBatchStatus: boolean,
) {
  for (const fetchPackage of packageList) {
    fetchPackage.excludedFromBatch = excludedFromBatchStatus;
  }
}
// append new packages onto original package list
function combinePackageList(
  packageListOriginal: IBatchPackageStoreType[],
  packageListNew: IPackageStoreType[],
  driverBatchId: number | null,
) {
  if (!packageListOriginal) {
    packageListOriginal = [];
  }
  // if list is currently empty or this is an existing batch
  // then automatically include new packages, else exclude by default
  const newPackageExcludedFromBatchStatus =
    packageListOriginal.length !== 0 || driverBatchId !== null;
  // set all new packages as not included in batch
  setPackagesIncludedInBatchStatus(
    packageListNew,
    newPackageExcludedFromBatchStatus,
  );
  const packageIdObject = packageListOriginal.reduce(
    (map: { [index: string]: boolean }, obj) => {
      map[obj.fetchPackageId.toString()] = true;
      return map;
    },
    {},
  );
  for (const fetchPackage of packageListNew) {
    if (!packageIdObject.hasOwnProperty(fetchPackage.fetchPackageId)) {
      packageListOriginal.push(
        convertPackageStoreTypeToBatchPackageStoreType(fetchPackage),
      );
    }
  }
  return packageListOriginal;
}

function convertPackageStoreTypeToBatchPackageStoreType(
  fetchPackage: IPackageStoreType,
) {
  const batchPackage = fetchPackage as IBatchPackageStoreType;
  batchPackage.warehouseLocation = {
    warehouseLocationId: fetchPackage.warehouseLocationId as number,
  };
  return batchPackage;
}
