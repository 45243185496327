export const RECEIVED = 2;
export const DELIVERY_SCHEDULED = 3;
export const OUT_FOR_DELIVERY = 4;
export const DELIVERED = 5;
export const EXCEPTION_TRIED_ONCE = 6;
export const EXCEPTION_TRIED_TWICE = 7;
export const PICKUP_REQUESTED = 10;
export const BATCHED = 11;

export type TTrackerStatusOptions =
  | "received"
  | "scheduled"
  | "out-for-delivery"
  | "delivered"
  | "pickup-requested";

export const enum PackageStatuses {
  PENDING_RECEIVE = 1,
  RECEIVED = 2,
  DELIVERY_SCHEDULED = 3,
  OUT_FOR_DELIVERY = 4,
  DELIVERED = 5,
  EXCEPTION_TRIED_ONCE = 6,
  EXCEPTION_TRIED_TWICE = 7,
  PICKUP_REQUESTED = 10,
  BATCHED = 11,
}
