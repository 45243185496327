import { call, put, select } from "redux-saga/effects";

import { generalService } from "services/hermes/general";
import { networkActions } from "slices/authentication/network.actions";
import { IRootStateType } from "types/IRootStateType";

const LOAD_APPLICATION_SETTINGS_SUCCESS = "LOAD_APPLICATION_SETTINGS_SUCCESS";

export interface IApplicationSettingsStoreType {
  senderLogos: Record<string, string>;
  packageDeliveryPolicies: Record<string, string>;
  warehouseLocationTypes: Record<string, string>;
  deliveryMethods: Record<string, string>;
  packageDeliveryPreferences: Record<string, string>;
  packageAuditTypes: Record<string, string>;
  fetchOfferingTypes: Record<string, string>;
  fetchPackageTypes: Record<string, string>;
  deliveryReceiptMethods: Record<string, string>;
  carriers: Record<string, string>;
  batchAuditStatusOptions: Record<string, string>;
  packageStatusOptions: Record<string, string>;
  autoScheduleDeliveryOptions: Record<string, string>;
  leaveLiabilityWaiverUrl: string;
  residentTermsAndConditionsUrl: string;
  stripePublicKey: string;
  loadedAt: number;
  wfmPayEntryState: Record<string, string>;
  wfmPayEntrySources: Record<string, string>;
  wfmPayEntrySourcesUploadAllowed: Record<string, string>;
  wfmBlockStates: Record<string, string>;
  driverClassifications: Record<string, string>;
  wfmBlockCancellationReasonCodes: Record<string, string>;
}

interface ILoadApplicationSettingActionType {
  type: typeof LOAD_APPLICATION_SETTINGS_SUCCESS;
  payload: IApplicationSettingsStoreType;
}

type ApplicationSettingsActionsTypes = ILoadApplicationSettingActionType;

export function applicationSettings(
  state = {},
  action: ApplicationSettingsActionsTypes,
): Partial<IApplicationSettingsStoreType> {
  switch (action.type) {
    case LOAD_APPLICATION_SETTINGS_SUCCESS:
      const payload = action.payload;
      payload.loadedAt = new Date().getTime();
      return payload;
    default:
      return state;
  }
}

function loadApplicationSettingsSuccess(
  applicationSettingsResponse: IApplicationSettingsStoreType,
) {
  return {
    payload: applicationSettingsResponse,
    type: LOAD_APPLICATION_SETTINGS_SUCCESS,
  };
}

const applicationSettingsActions = {
  loadApplicationSettingsSuccess,
};

export function* loadApplicationSettingsWorker() {
  const getApplicationSettings = (state: IRootStateType) =>
    state.applicationSettings;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const applicationSettings: IApplicationSettingsStoreType = yield select(
    getApplicationSettings,
  );
  if (!("loadedAt" in applicationSettings)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof generalService.getApplicationSettings
    > = yield call(generalService.getApplicationSettings);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IApplicationSettingsStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(
      applicationSettingsActions.loadApplicationSettingsSuccess(response),
    );
  }
}
