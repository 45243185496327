import { takeLeading, ForkEffect, call, put, select } from "redux-saga/effects";

import { networkActions } from "slices/authentication/network.actions";
import { history } from "helpers/history";
import { packagesActions } from "slices/packages/packages.actions";
import { usersActions } from "slices/users/users.actions";
import { getEncodedSystemIdFromSystemId } from "helpers/system-id.helper";
import { batchActions } from "slices/batch/batch.actions";
import { packageActions } from "slices/package/package.actions";
import { userActions } from "slices/user/user.actions";
import { IRootStateType } from "types/IRootStateType";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IBatchStoreType } from "./batch/batch.reducer";
import { IPackageStoreType } from "./package/package.reducer";
import { IPackagesStoreType } from "./packages/packages.reducer";
import { IUserStoreType } from "./currentUser/currentUser.reducer";
import { IUsersStoreType } from "./users/users.reducer";
import { packagesService } from "services/hermes/packages";
import { usersService } from "services/hermes/users";

// Quick Search
const PERFORM_SEARCH = "PERFORM_SEARCH";

type ResourceType = "package" | "batch" | "user";

interface IPerformSearchPayloadType {
  resourceType: ResourceType;
  searchValue: string;
}

interface IPerformSearchActionType {
  type: typeof PERFORM_SEARCH;
  payload: IPerformSearchPayloadType;
}

type FailureHandler = () => { type: string };

type PackageSearchAction = (packageResponse: IPackagesStoreType) => {
  type: string;
  payload: IPackagesStoreType;
};

type UsersSearchAction = (userResponse: IUsersStoreType) => {
  type: string;
  payload: IUsersStoreType;
};

type SearchResultMatch = IBatchStoreType | IPackageStoreType | IUserStoreType;

export const quickSearchActions = {
  performSearch: (
    payload: IPerformSearchPayloadType,
  ): IPerformSearchActionType => {
    return { type: PERFORM_SEARCH, payload };
  },
};

function getMatchedItem(
  searchResults: (IBatchStoreType | IPackageStoreType | IUserStoreType)[],
  resourceType: ResourceType,
  searchValue: string,
) {
  return searchResults.reduce(
    (
      matchedItem: IBatchStoreType | IPackageStoreType | IUserStoreType | null,
      item: IBatchStoreType | IPackageStoreType | IUserStoreType,
    ): IBatchStoreType | IPackageStoreType | IUserStoreType | null => {
      switch (resourceType) {
        case "batch":
          if (
            "driverBatchId" in item &&
            !!item.driverBatchId &&
            `${item.driverBatchId}` === searchValue
          ) {
            return item;
          }
          break;
        case "package":
          if (
            "fetchPackageId" in item &&
            !!item.fetchPackageId &&
            `${item.fetchPackageId}` === searchValue
          ) {
            return item;
          }
          break;
        case "user":
          if (
            "userCode" in item &&
            !!item.userCode &&
            item.userCode === searchValue
          ) {
            return item;
          }
          break;
      }
      return matchedItem;
    },
    null,
  );
}

const assertSearchValueInSearchResults = (
  searchResults: (IBatchStoreType | IPackageStoreType | IUserStoreType)[], // https://github.com/microsoft/TypeScript/issues/33591
  resourceType: ResourceType,
  searchValue: string,
): [boolean, SearchResultMatch | null] => {
  const matchedItem = getMatchedItem(searchResults, resourceType, searchValue);
  const matchWasFound = !!matchedItem;
  return [matchWasFound, matchedItem];
};

const getResponseHandler = (
  resourceType: ResourceType,
  searchValueInSearchResults: boolean,
): PackageSearchAction | UsersSearchAction | FailureHandler => {
  switch (resourceType) {
    case "batch":
      return searchValueInSearchResults
        ? packagesActions.searchPackagesSuccess
        : packagesActions.searchPackagesFailure;
    case "package":
      return searchValueInSearchResults
        ? packagesActions.searchPackagesSuccess
        : packagesActions.searchPackagesFailure;
    case "user":
      return searchValueInSearchResults
        ? usersActions.searchUsersSuccess
        : usersActions.searchUsersFailure;
  }
};

function* getNewRoute(
  resourceType: ResourceType,
  searchValue: string,
  searchValueInSearchResults?: boolean,
) {
  if (
    (typeof searchValueInSearchResults === "boolean" &&
      searchValueInSearchResults) ||
    typeof searchValueInSearchResults === "undefined"
  ) {
    switch (resourceType) {
      case "batch":
        return `/packages?pageSize=20&driverBatchId=${searchValue}`;
      case "package":
        return `/package/${searchValue}`;
      case "user":
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const users: IUsersStoreType = yield select((state: IRootStateType) => {
          return state.users;
        });
        const systemId: string = users.content.reduce((systemId, user) => {
          if (user.userCode === searchValue) {
            systemId = user.systemId;
          }
          return systemId;
        }, "");
        return `/user/${getEncodedSystemIdFromSystemId(systemId)}`;
      default:
        return "/";
    }
  } else {
    switch (resourceType) {
      case "batch":
        return `/packages?pageSize=20&driverBatchId=${searchValue}`;
      case "package":
        return `/packages?pageSize=20&fetchPackageId=${searchValue}`;
      case "user":
        return `/users?userCode=${searchValue}`;
      default:
        return "/";
    }
  }
}

function* loadFormState(matchedItem: SearchResultMatch) {
  if (
    "driverBatchId" in matchedItem &&
    !("fetchPackageId" in matchedItem) &&
    !!matchedItem.driverBatchId
  ) {
    yield put(batchActions.getBatchSuccess(matchedItem));
  }
  if ("fetchPackageId" in matchedItem && !!matchedItem.fetchPackageId) {
    yield put(packageActions.getPackageSuccess(matchedItem));
  }
  if ("privileges" in matchedItem) {
    yield put(userActions.getUserSuccess(matchedItem));
  }
}

const performSearch = (
  resourceType: ResourceType,
  searchValue: string,
): IAuthenticatedRequestDefinition => {
  switch (resourceType) {
    case "batch":
      return packagesService.searchPackages({
        filters: [
          { id: "driverBatchId", value: searchValue },
          { id: "pageSize", value: "20" },
        ],
      });
    case "package":
      return packagesService.searchPackages({
        filters: [
          { id: "fetchPackageId", value: searchValue },
          { id: "pageSize", value: "20" },
        ],
      });
    case "user":
      return usersService.searchUsers({
        filters: [
          { id: "userCode", value: searchValue },
          { id: "pageSize", value: "20" },
        ],
      });
  }
};

function* performSearchWorker({
  payload: { resourceType, searchValue },
}: IPerformSearchActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      performSearch,
      resourceType,
      searchValue,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const searchResults: IUsersStoreType & IPackagesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    const [searchValueInSearchResults, matchedItem] =
      assertSearchValueInSearchResults(
        searchResults.content,
        resourceType,
        searchValue,
      );
    if (searchValueInSearchResults && !!matchedItem) {
      yield call(loadFormState, matchedItem);
    }
    const responseHandler = getResponseHandler(
      resourceType,
      searchValueInSearchResults,
    );
    yield put(responseHandler(searchResults));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newRoute: string = yield call(
      getNewRoute,
      resourceType,
      searchValue,
      searchValueInSearchResults,
    );
    history.push(newRoute);
  } catch (error) {
    const responseHandler = getResponseHandler(
      resourceType,
      false,
    ) as FailureHandler;
    yield put(responseHandler());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newRoute: string = yield call(
      getNewRoute,
      resourceType,
      searchValue,
      false,
    );
    history.push(newRoute);
  }
}

export const quickSearchSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(PERFORM_SEARCH, performSearchWorker);
};
