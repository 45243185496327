import { ComponentType } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  IRootStateType,
  IRootStateTypeIndex,
  StoresUnionType,
} from "types/IRootStateType";

const mapStoresToProps =
  (stores: IRootStateTypeIndex[]) => (state: IRootStateType) => {
    const props: PropsType = {};
    stores.forEach((store: IRootStateTypeIndex) => {
      const storeValue: StoresUnionType = state[store];
      if (storeValue && typeof storeValue === "object") {
        props[store] = storeValue;
      }
    });
    return props;
  };

export interface IStateMappingOptions {
  initialValues?: IRootStateTypeIndex;
  stores?: IRootStateTypeIndex[];
  mapStateToProps?: (state: IRootStateType) => PropsType;
}

const handleStateMapping = (options: IStateMappingOptions) => {
  if (options.stores) {
    return mapStoresToProps(options.stores);
  }
  if (options.mapStateToProps) {
    return options.mapStateToProps;
  }
};

export const FetchConnect = ({
  state,
  ConnectedComponent,
}: {
  state: IStateMappingOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConnectedComponent: ComponentType<any>; // TODO: Resolve this type.
}) => {
  return connect(handleStateMapping(state))(withRouter(ConnectedComponent));
};

type PropsType = {
  [key in IRootStateTypeIndex]?: StoresUnionType;
};
