import React from "react";
import { FetchConnect } from "helpers/FetchConnect";
import ConfirmResetPasswordModal from "views/components/modals/ConfirmResetPasswordModal";
import { LeaveLiabilityWaiverModal } from "views/components/modals/LeaveLiabilityWaiverModal";
import VerifyUserEmailFormModal from "views/components/modals/VerifyUserEmailFormModal";
import VerifyUserPhoneFormModal from "views/components/modals/VerifyUserPhoneFormModal";
import { UseOurMobileAppModal } from "views/components/modals/UseOurMobileAppModal";
import { IRootStateType } from "types/IRootStateType";

export const FetchModalService: React.FunctionComponent<IRootStateType> = (
  props: IRootStateType,
) => {
  const {
    ui: {
      modals: {
        verify_phone,
        verify_email,
        leave_liability_waiver,
        confirm_reset_password,
        use_our_mobile_app,
      },
    },
  } = props;

  return (
    <div>
      {verify_phone && <VerifyUserPhoneFormModal />}
      {verify_email && <VerifyUserEmailFormModal />}
      {leave_liability_waiver && <LeaveLiabilityWaiverModal />}
      {confirm_reset_password && <ConfirmResetPasswordModal />}
      {use_our_mobile_app && <UseOurMobileAppModal />}
    </div>
  );
};

export default FetchConnect({
  ConnectedComponent: FetchModalService,
  state: { stores: ["ui"] },
});
