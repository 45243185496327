export function generateHermesNetworkErrorMessage(error: unknown): string {
  return !!error &&
    typeof error === "object" &&
    "fetchErrorCode" in error &&
    typeof error.fetchErrorCode === "number" &&
    "message" in error &&
    typeof error.message === "string"
    ? `[Fetch Error Code: ${error.fetchErrorCode}] ${error.message}`
    : "An error occured. Please try again.";
}
