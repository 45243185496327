import { networkActions } from "slices/authentication/network.actions";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";

const CREATE_LABEL_BUNDLE = "CREATE_LABEL_BUNDLE";
const PRINT_LABEL_BUNDLE = "PRINT_LABEL_BUNDLE";

// Interfaces
interface ICreateLabelBundleActionType {
  type: typeof CREATE_LABEL_BUNDLE;
  query: ICreateLabelBundleActionPayloadType;
}

interface ICreateLabelBundleActionPayloadType {
  requestedCount: number;
  warehouseId: number;
  meta?: Record<string, unknown>;
}

interface IPrintBundleActionType {
  type: typeof PRINT_LABEL_BUNDLE;
  query: IPrintBundleActionPayloadType;
}

interface IPrintBundleActionPayloadType {
  bundleId: number;
}

// Action
function createLabelBundle(
  requestedCount: number,
  warehouseId: number,
): ICreateLabelBundleActionType {
  return {
    type: CREATE_LABEL_BUNDLE,
    query: { requestedCount: requestedCount, warehouseId: warehouseId },
  };
}

function printLabelBundle(bundleId: number): IPrintBundleActionType {
  return { type: PRINT_LABEL_BUNDLE, query: { bundleId: bundleId } };
}

export const labelBundleActions = {
  createLabelBundle,
};

interface IPrintLabelBundleResponse {
  bundleId: number;
  createdByUserId: number;
  createdDate: string;
  requestedCount: number;
  warehouseId: number;
}

interface ICreateLabelBundleResponse extends IPrintLabelBundleResponse {
  remainingLabelIds: number[];
}

function* createLabelBundleWorker(action: ICreateLabelBundleActionType) {
  const { query } = action;

  try {
    const requestOptions: IAuthenticatedRequestDefinition = {
      requestUrl: "/labels/bundles",
      requestOptions: { method: "POST" },
      body: {
        requestedCount: query.requestedCount,
        warehouseId: query.warehouseId,
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: ICreateLabelBundleResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    yield put(printLabelBundle(response.bundleId));
  } catch (error) {
    toast.error(
      "There was an error creating label bundle. Please refresh and try again.",
    );
  }

  return { type: CREATE_LABEL_BUNDLE };
}

function* printLabelBundleActionWorker(action: IPrintBundleActionType) {
  const { query } = action;

  try {
    const requestOptions: IAuthenticatedRequestDefinition = {
      requestUrl: `/labels/bundles/print/${query?.bundleId}`,
      requestOptions: { method: "POST" },
    };
    yield call(networkActions.makeAuthenticatedRequest, requestOptions);

    toast.success("Bulk Label batch will begin printing.");
  } catch (error) {
    toast.error(
      "There was an error printing this label bundle. Please refresh and try again.",
    );
  }

  return { type: PRINT_LABEL_BUNDLE };
}

// "Watcher" set up
export const printLabelBundleSagas = function* () {
  yield takeLatest(PRINT_LABEL_BUNDLE, printLabelBundleActionWorker);
};

// "Watcher" set up
export const createLabelBundleSagas = function* () {
  yield takeLatest(CREATE_LABEL_BUNDLE, createLabelBundleWorker);
};
