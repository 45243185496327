import { IBatchesStoreType } from "slices/batches/batches.reducer";
import { IBatchStoreType } from "./batch.reducer";

export const CLEAR_BATCH = "CLEAR_BATCH";
export const SAVE_BATCH = "SAVE_BATCH";
export const SAVE_BATCH_SUCCESS = "SAVE_BATCH_SUCCESS";
export const GET_BATCH = "GET_BATCH";
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS";
export const CLEAR_BATCH_PACKAGES = "CLEAR_BATCH_PACKAGES";
export const GET_BATCH_PACKAGES = "GET_BATCH_PACKAGES";
export const GET_PACKAGES_FROM_BATCH = "GET_PACKAGES_FROM_BATCH";
export const GET_PACKAGES_FROM_BATCH_SUCCESS =
  "GET_PACKAGES_FROM_BATCH_SUCCESS";
export const EXCLUDE_PACKAGE_FROM_BATCH = "EXCLUDE_PACKAGE_FROM_BATCH";
export const EXCLUDE_PACKAGES_FROM_BATCH = "EXCLUDES_PACKAGES_FROM_BATCH";
export const INCLUDE_PACKAGE_IN_BATCH = "INCLUDE_PACKAGE_IN_BATCH";
export const INCLUDE_PACKAGES_IN_BATCH = "INCLUDE_PACKAGES_IN_BATCH";

export interface IGetBatchPackagesActionType {
  type: typeof GET_BATCH_PACKAGES;
  payload: {
    batchId: string;
    buildingId: string;
    physicalWarehouseId?: number | null;
    deliveryWindowId: number;
  };
}
export interface IClearBatchActionType {
  type: typeof CLEAR_BATCH;
}
export interface IClearBatchPackagesActionType {
  type: typeof CLEAR_BATCH_PACKAGES;
}
export interface IExcludePackageFromBatchActionType {
  type: typeof EXCLUDE_PACKAGE_FROM_BATCH;
  payload: number;
}
export interface IGetBatchActionType {
  type: string;
  payload: {
    batchId: string;
  };
}

export interface IGetBatchSuccessActionType {
  type: typeof GET_BATCH_SUCCESS;
  payload: IBatchStoreType;
}
export interface IGetPackagesFromBatchSuccessActionType {
  type: typeof GET_PACKAGES_FROM_BATCH_SUCCESS;
  payload: IBatchStoreType;
}
export interface IIncludePackageInBatchActionType {
  type: typeof INCLUDE_PACKAGE_IN_BATCH;
  payload: number;
}

export interface ISaveBatchActionType {
  type: typeof SAVE_BATCH;
  payload: IBatchStoreType;
  onSuccessConfig?: {
    callback?: (batch: IBatchStoreType) => void;
    resetForm?: boolean;
  };
}
export interface IExcludePackagesFromBatchActionType {
  type: typeof EXCLUDE_PACKAGES_FROM_BATCH;
  payload: number[];
}

export interface IIncludePackagesInBatchActionType {
  type: typeof INCLUDE_PACKAGES_IN_BATCH;
  payload: number[];
}

function getBatchPackages(
  buildingId: string,
  deliveryWindowId: string,
  batchId?: string,
  physicalWarehouseId?: number | null,
) {
  return {
    payload: {
      batchId,
      buildingId,
      physicalWarehouseId,
      deliveryWindowId,
    },
    type: GET_BATCH_PACKAGES,
  };
}

function clearBatch() {
  return { type: CLEAR_BATCH };
}

function clearBatchPackages() {
  return {
    type: CLEAR_BATCH_PACKAGES,
  };
}

function excludePackageFromBatch(fetchPackageId: number) {
  return { type: EXCLUDE_PACKAGE_FROM_BATCH, payload: fetchPackageId };
}

function getBatch(batchId: string) {
  return { type: GET_BATCH, payload: { batchId } };
}

function getBatchSuccess(batchResponse: IBatchStoreType) {
  return { type: GET_BATCH_SUCCESS, payload: batchResponse };
}

function getPackagesFromBatch(batchId: string) {
  return { type: GET_PACKAGES_FROM_BATCH, payload: { batchId } };
}

function getPackagesFromBatchSuccess(batchResponse: IBatchStoreType) {
  return { type: GET_PACKAGES_FROM_BATCH_SUCCESS, payload: batchResponse };
}

function includePackageInBatch(fetchPackageId: number) {
  return { type: INCLUDE_PACKAGE_IN_BATCH, payload: fetchPackageId };
}

function saveBatch(
  formData: IBatchStoreType,
  onSuccessConfig?: ISaveBatchActionType["onSuccessConfig"],
) {
  return { type: SAVE_BATCH, payload: formData, onSuccessConfig };
}

function saveBatchSuccess(
  batchResponse: IBatchesStoreType,
  onSuccessConfig?: ISaveBatchActionType["onSuccessConfig"],
) {
  return { type: SAVE_BATCH_SUCCESS, payload: batchResponse, onSuccessConfig };
}

function excludePackagesFromBatch(
  fetchPackageIds: number[],
): IExcludePackagesFromBatchActionType {
  return { type: EXCLUDE_PACKAGES_FROM_BATCH, payload: fetchPackageIds };
}

function includePackagesInBatch(
  fetchPackageIds: number[],
): IIncludePackagesInBatchActionType {
  return { type: INCLUDE_PACKAGES_IN_BATCH, payload: fetchPackageIds };
}

export const batchActions = {
  clearBatch,
  clearBatchPackages,
  excludePackageFromBatch,
  excludePackagesFromBatch,
  getBatch,
  getBatchPackages,
  includePackageInBatch,
  includePackagesInBatch,
  saveBatch,
  getBatchSuccess,
  getPackagesFromBatchSuccess,
  saveBatchSuccess,
  getPackagesFromBatch,
};
