export enum FeatureFlags {
  "display-service-delay-message" = "display-service-delay-message",
  "disable-warehouse-pickup" = "disable-warehouse-pickup",
  "treats-targeted-list" = "treats-targeted-list",
  "storage-by-building-v2" = "storage-by-building-v2",
  "warehouse-address-changed" = "warehouse-address-changed",
  "driver-route-builder" = "driver-route-builder",
  "concierge-building" = "concierge-building",
  "enable-manual-logging" = "enable-manual-logging",
  "can-create-asap-windows" = "can-create-asap-windows",
  "test-driver-warehouse" = "test-driver-warehouse",
  "add-temporary-delivery-windows" = "add-temporary-delivery-windows",
  "driver-csat" = "driver-csat",
  "branch-wallet-status" = "branch-wallet-status",
  "display-QR-AR-toggle-courier" = "display-QR-AR-toggle-courier",
  "ios-networking-test" = "ios-networking-test",
  "logging-queue-alert" = "logging-queue-alert",
  "driver-second-attempt-poad" = "driver-second-attempt-poad",
  "log-to-location-flow" = "log-to-location-flow",
  "create-volume-cutoffs" = "create-volume-cutoffs",
  "display-logging-v2-form-webapp" = "display-logging-v2-form-webapp",
  "driver-second-attempt-timer" = "driver-second-attempt-timer",
  "residentBanner" = "residentBanner",
  "ios-networking-test-m4-warehouse" = "ios-networking-test-m4-warehouse",
  "driver-second-attempt-timer-resident" = "driver-second-attempt-timer-resident",
  "static-data" = "static-data",
  "oversized-logging-allowed" = "oversized-logging-allowed",
  "remove-package-batch-audit" = "remove-package-batch-audit",
  "ios-networking-test-m1" = "ios-networking-test-m1",
  "ios-networking-test-m2" = "ios-networking-test-m2",
  "test-driver-warehouse-value" = "test-driver-warehouse-value",
  "ios-networking-test-m4" = "ios-networking-test-m4",
  "ios-networking-test-m5" = "ios-networking-test-m5",
  "resident-new-world-v1" = "resident-new-world-v1",
  "resident-new-world-v2" = "resident-new-world-v2",
  "storage-studentmovein" = "storage-studentmovein",
  "invalid-feature-flag-testing" = "invalid-feature-flag-testing",
  "res-store-mvp-web" = "res-store-mvp-web",
  "whs-store-pilot" = "whs-store-pilot",
  "res-independent-verified" = "res-independent-verified",
}
