import React from "react";
import { Button as ReactstrapButton, ButtonProps } from "reactstrap";
import classNames from "classnames";

export interface FDSButtonProps extends ButtonProps {
  fontSize?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  letterSpacing?: "normal" | "wide";
  schema?: keyof typeof Schemas;
}

const Button: React.FC<FDSButtonProps> = (
  props: React.PropsWithChildren<FDSButtonProps>,
) => {
  const {
    fontSize = "md",
    className,
    letterSpacing = "normal",
    schema,
  } = props;
  return (
    <ReactstrapButton
      {...props}
      className={classNames(
        "fds-font-family-sans-serif",
        `fds-letter-spacing-${letterSpacing}`,
        `fds-font-size-${fontSize}`,
        `fds-line-height-${fontSize}`,
        schema ? Schemas[schema].className : undefined,
        className,
      )}
    >
      {props.children}
    </ReactstrapButton>
  );
};

export default Button;

export const Schemas = {
  primary: {
    className: "fds-bg-blue-400 fds-text-neutral-white fds-bg-hover-blue-600",
  },
  success: {
    className: "fds-bg-green-400 fds-text-neutral-white fds-bg-hover-green-600",
  },
  secondary: {
    className:
      "fds-bg-neutral-700 fds-text-neutral-white fds-bg-hover-neutral-900",
  },
  tertiary: {
    className:
      "fds-bg-neutral-200 fds-text-neutral-800 fds-bg-hover-neutral-300 ",
  },
  danger: {
    className: "fds-bg-red-600 fds-text-neutral-white fds-bg-hover-red-700",
  },
  outline: {
    className: "fds-bg-neutral-white fds-text-neutral-800 border",
  },
  toolbar: {
    className:
      "fds-bg-neutral-white fds-text-neutral-600 fds-bg-hover-neutral-600 shadow-none px-3",
  },
};
