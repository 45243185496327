import React from "react";
import { Modal, ModalBody } from "reactstrap";
import Button from "views/fetch-design/Button/Button";
import verifiedDelivery from "assets/img/verifiedDelivery.svg";
import { VerifiedIntroModalSelectors } from "./VerifiedIntroModalSelectors";

type VerifiedIntroModalProps = {
  onClickConfirmButton: () => void;
};
export const VerifiedIntroModal: React.FC<VerifiedIntroModalProps> = (
  props,
) => {
  return (
    <Modal isOpen={true}>
      <ModalBody>
        <div className="text-center m-4">
          <img
            src={verifiedDelivery}
            alt="Resident verifying delivery code in app"
          />
        </div>
        <div
          className="text-center fds-heading-400 my-4"
          data-cy={VerifiedIntroModalSelectors.title}
        >
          Introducing Verified Delivery by Fetch
        </div>
        <div
          className="text-center fds-body-100 my-2"
          data-cy={VerifiedIntroModalSelectors.message}
        >
          Verified Delivery keeps your important packages secure with a unique
          code—no more signatures! Your code will be sent via SMS and can also
          be found here in the Fetch app. Simple, safe, and reliable. 🚚📦
        </div>
        <Button
          className="fds-bg-blue-500 mt-4 w-100 rounded-pill"
          data-cy={VerifiedIntroModalSelectors.confirmButton}
          onClick={props.onClickConfirmButton}
        >
          Got it!
        </Button>
      </ModalBody>
    </Modal>
  );
};
